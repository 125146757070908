import { useCallback, useState } from "react";

export const useMetric = (key: string) => {
    const [isMetric, setIsMetric] = useState(() => {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : false;
    });

    const toMetricTon = useCallback((v: number | undefined) => {
        if (v === undefined || v === 0) return 0;
        return isMetric ? Math.floor(v / 268.47) : v;
    }, [isMetric]);

    const toMetricTonString = useCallback((v: number | undefined, useDashForZero = true) => {
        if (v === undefined || v === 0) return useDashForZero ? '-' : 0;
        const num = isMetric ? (Math.floor(v / 268.47)) : v;
        return num < 0 ? `(${Math.abs(num).toLocaleString()})` : num.toLocaleString();
    }, [isMetric]);

    const toggleUnit = useCallback(() => {
        localStorage.setItem(key, JSON.stringify(!isMetric));
        setIsMetric(!isMetric);
    }, [isMetric, key]);

    return {
        isMetric,
        toggleUnit,
        toMetricTon,
        toMetricTonString
    }
}
