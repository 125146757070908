import { faDashboard, faFolderPlus, faList } from '@fortawesome/free-solid-svg-icons';
import { ILoadingRequestDetailDto, IProductDto, IUserEntities, PutOrderDto } from 'appDtos';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert, { AlertType } from '../../Shared/components/Alert';
import ControlInputField from '../../Shared/components/ControlInputField';
import Loading from '../../Shared/components/Loading';
import { BackButton, NavigationButton, SubmitButton } from '../../Shared/components/NavigationButton';
import RequiredText from '../../Shared/components/RequiredText';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import useAuth from '../../Shared/hooks/useAuth';
import useChangeDetector from '../../Shared/hooks/useChangeDetector';
import useFormProcessor from '../../Shared/hooks/useFormProcessor';
import { scrollToTop } from '../../Shared/utils/windowUtil';
//import Icon from '../../Shared/components/Icon';

interface PutOrder extends PutOrderDto { }

export default function EditOrder() {
  const { id } = useParams();
  const goTo = useNavigate();
  const axios = useApi();
  const [results, setResult] = React.useState<IUserEntities>({ importers: [], distributors: [] });
  const ref = React.useRef(false);
  const [formErrorMessage, setErrorMesssage] = React.useState('');
  const { errorMessage, setError } = useApiHandleError();
  const { hasChanged, setInitialValues, checkChanges, renderNoChangeComponnent } = useChangeDetector();
  const { isInRole, hasPermission, rCodes, pCodes } = useAuth();
  const {
    handleOnChange,
    handleFieldError,
    setIsProcessing,
    isProcessing,
    formData,
  } = useFormProcessor<PutOrder>({
    id,
    importerId: '',
    distributorId: '',
    transferOrderNumber: '',
    productId: '',
    quantity: '',
  });

  React.useEffect(() => {
    checkChanges({
      importerId: Number(formData.importerId),
      distributorId: Number(formData.distributorId),
      productId: Number(formData.productId),
      quantity: Number(formData.quantity),
      transferOrderNumber: formData.transferOrderNumber,
    });
  }, [formData, checkChanges])

  React.useEffect(() => {
    if (!ref.current) {
      setIsProcessing(true);
      const fetch = async () => {
        if (id) {
          try {
            const orderResult = await axios.get<ILoadingRequestDetailDto>(`/TransferOrder/${id}`);

            if (orderResult.data) {
              const { id, importer, distributor, product, quantity, transferOrderNumber } = orderResult.data;
              handleOnChange({
                id,
                importerId: importer?.id,
                distributorId: distributor?.id,
                productId: product?.id,
                quantity,
                transferOrderNumber,
              });
              setInitialValues({
                importerId: importer?.id,
                distributorId: distributor?.id,
                productId: product?.id,
                quantity,
                transferOrderNumber,
              });
              const entityResult = await axios.post<IUserEntities>('User/Entities', {
                includeImporters: true,
                includeDistributors: true,
                ensureImporterAuthorization: true,
                ensureDistributorAuthorization: false,
              });

              if (entityResult?.data) {
                setResult(entityResult.data);
              }
            }
          } catch (error: any) {
            setError(error);
          }
        }
        setIsProcessing(false);
      }

      fetch();
    }

    return () => {
      ref.current = true;
    };
  }, [id, axios, setInitialValues, results, handleOnChange, setIsProcessing, setError]);

  React.useEffect(() => {
    setErrorMesssage('');
    setError('');
  }, [formData, setError]);

  const products = React.useMemo(() => {
    if (!formData.importerId) return [];
    const importer = results.importers.find(i => i.id === Number(formData.importerId));
    if (!importer) return [];
    let importerProducts: { product: IProductDto, balance: number }[] = [];
    for (const inventory of importer.inventories) {
      if (!importerProducts.find(i => i.product.id === inventory.product.id)) {
        importerProducts = [...importerProducts, { product: inventory.product, balance: inventory.adjustedStockBalance }];
      }
    }
    return importerProducts;
  }, [formData.importerId, results.importers]);

  const balance = products.find(x => x.product.id === Number(formData.productId))?.balance;

  const handleSubmit = async () => {
    const missingValues = Object.values(formData).filter((value) => !value);

    if (missingValues.length) {
      scrollToTop();
      return setErrorMesssage('Please complete all required fields.');
    }

    const limit = Number(balance);
    const inputedQty = Number(formData.quantity);

    if (inputedQty > limit) {
      scrollToTop();
      return setErrorMesssage(`The inputed quantity of ${inputedQty.toLocaleString()} is greater than the inventory adjusted stock balance of ${limit.toLocaleString()}.`);
    }

    setIsProcessing(true);

    try {
      await axios.put('TransferOrder/', { order: formData });
      return goTo(`/transfer-order-detail/${id}`);
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  const handleImporterSelect = (e: any) => {
    handleOnChange({ importerId: e.target.value });
  };

  const handleProductSelect = (e: any) => {
    handleOnChange({ productId: e.target.value });
  };

  const handleQuantityChange = (e: any) => {
    handleOnChange({ quantity: e.target.value.replace(/\D/g, '') });
  };

  const handleDistributorSelect = (e: any) => {
    handleOnChange({ distributorId: e.target.value });
  };

  // const isEmptyInventory = Boolean(formData.productId) && !Boolean(balance);
  // const hasProducts = Boolean(formData.productId) && Boolean(balance);

  return (
    <div className="container app-container">
      <div className="d-flex-jcb">
        <div className="h4 text-dark">Edit Transfer Order</div>
        <div className="d-flex-jce">
          {isInRole(rCodes?.R100) && hasPermission(pCodes?.P200) && <NavigationButton link="/create-transfer-order" icon={faFolderPlus} label="Create Transfer Order" />}
          <NavigationButton link="/transfer-orders/list" icon={faList} label="Transfer Orders" />
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      {isProcessing && <Loading loadingText="Loading transfer order detail." position="center" />}
      {(!!errorMessage || !!formErrorMessage) && <Alert type={AlertType.DANGER} message={errorMessage || formErrorMessage} />}
      {!!formData.importerId && <>
        <div className="col mb-3">
          <ControlInputField
            onChange={handleOnChange}
            autoComplete="off"
            onFieldError={handleFieldError}
            label="Transfer Order Number"
            placeholder="Enter transfer order number."
            id="transferOrderNumber"
            value={formData.transferOrderNumber}
            name="transferOrderNumber"
            type="text"
            required={true}
          />
        </div>
        <div className="col mb-3">
          <div className="form-label fw-bold">Importer <RequiredText /></div>
          <select className="form-select" aria-label="Default select example" onChange={handleImporterSelect}>
            <option selected>Select importer</option>
            {results.importers.map(d => <option value={d.id} key={d.id} selected={d.id === Number(formData.importerId)}>{d.name}</option>)}
          </select>
        </div>
        <div className="col mb-3">
          <div className="form-label fw-bold">Product <RequiredText /></div>
          <select className="form-select" aria-label="Default select example" onChange={handleProductSelect}>
            <option selected>Select Product</option>
            {products.map(d => <option value={d.product.id} key={d.product.id} selected={d.product.id === Number(formData.productId)}>{d.product.name}</option>)}
          </select>
        </div>
        <div className="col mb-3">
          <label htmlFor="quantity" className="form-label fw-bold">Product Quantity <RequiredText /></label>
          <input
            onChange={handleQuantityChange}
            // disabled={isEmptyInventory}
            type="text"
            value={formData.quantity}
            name="quantity"
            className="form-control"
            placeholder="Enter product quantity"
            id="quantity"
            aria-describedby="quantity field"
            required
          />
          {/* {isEmptyInventory && <span className='text-danger fst-italic'><Icon icon={faExclamationTriangle} /> Importer inventory is empty for this product.</span>} */}
          {/* {hasProducts && <span><strong>Note:</strong> Quantity must be less than or equal to <strong>{balance?.toLocaleString()}</strong>.</span>} */}
        </div>
        <div className="col mt-3 mb-4">
          <div className="form-label fw-bold">Distributor <RequiredText /></div>
          <select className="form-select" aria-label="Default select example" onChange={handleDistributorSelect}>
            <option selected>Select distributor</option>
            {results.distributors.map(d => <option value={d.id} key={d.id} selected={d.id === Number(formData.distributorId)}>{d.name}</option>)}
          </select>
        </div>
      </>}
      {renderNoChangeComponnent()}
      <BackButton disabled={isProcessing} />
      {hasChanged && <SubmitButton handleSubmit={handleSubmit} disabled={isProcessing} />}
    </div>
  );
}

