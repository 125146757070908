import React, { useEffect, useState } from 'react'
import { StorageKeys } from '../../Shared/hooks/useLocalStorage';
import { faPrint, faRefresh } from '@fortawesome/free-solid-svg-icons';
import Icon from '../../Shared/components/Icon';
import useApi from '../../Shared/hooks/useApi';
import Alert, { AlertType } from '../../Shared/components/Alert';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import Loading from '../../Shared/components/Loading';
import { useMetric } from '../../Shared/hooks/useMetric';
import Toggle from './components/Toggle';
import { DateRangePickerComponent } from '../../Shared/components/DatePicker';
import { DateRange } from '../../Shared/components/DatePickerComponent';
import { setMidnight } from '../../Shared/utils/date';
import { reportType } from '../../Shared/constant';
import { usePrinterRef } from '../../Shared/hooks/usePrinterRef';
import { PrintingVersion } from './components/PrintingVersion';
import { useReportExporter } from './components/useReportExporter';

interface Report {
    importer: string;
    byProduct: BalanceByProduct
}

interface BalanceByProduct {
    pms: number;
    ago: number;
    jet: number;
}

interface Filter {
    from: Date | null,
    to: Date | null
}

export const OvernightDifferencesReport = () => {
    const API = useApi();
    const { toMetricTon, toggleUnit, isMetric, toMetricTonString } = useMetric('__ondByImp');
    const { errorMessage, setError } = useApiHandleError();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [filter, setFilter] = useState<Filter>({ from: new Date(), to: new Date() })
    const { componentRef, handlePrint } = usePrinterRef();
    const { tableRef, Button } = useReportExporter({ sheet: reportType.OvernightDifferences });
    const [reports, setReports] = useState<Report[]>(() => {
        const report = localStorage.getItem(StorageKeys.OND_BY_IMPOTERS);
        if (report) {
            return JSON.parse(report);
        }
        return [];
    });

    useEffect(() => {
        (async () => {
            if (!reports.length || refresh) {
                setLoading(true);
                errorMessage && setError('');
                try {
                    const { from, to } = filter;
                    const data = { from: setMidnight(from), to: setMidnight(to, true), type: 3 };
                    const res = await API.post('/Report/LosesReport', data);
                    localStorage.setItem(StorageKeys.OND_BY_IMPOTERS, JSON.stringify(res.data));
                    setReports(res.data);
                } catch (error) {
                    setError(error)
                }
                setRefresh(false);
                setLoading(false);
            }
        })()
    }, [reports.length, refresh, API, setError, errorMessage, filter])

    const total = reports.reduce((r, e) => {
        r.ago += toMetricTon(e.byProduct.ago);
        r.pms += toMetricTon(e.byProduct.pms);
        r.jet += toMetricTon(e.byProduct.jet);
        // r.jet += e.byProduct.hfo || 0;
        return r;
    }, {
        ago: 0,
        pms: 0,
        jet: 0,
    });

    // const handleImporterChange = (id: string) => setFilter({...filter, importerId: id});
    // const handleProductChange = (id: string) => setFilter({...filter, productId: id});
    const handleDateChange = (dateRange: DateRange) => setFilter({ ...filter, ...dateRange });

    const renderComponent = () => {
        return (
            <div className="border p-0">
                <table className="table table-sm table-responsive m-0" ref={tableRef}>
                    <thead className="table-secondary">
                        <tr>
                            <th className="fixed-width">#</th>
                            <th scope="col w-50">Importer</th>
                            <th scope="col">AGO</th>
                            <th scope="col">PMS</th>
                            <th scope="col">JET-A1</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.map(({ importer, byProduct: { ago, pms, jet } }, i) =>
                            <tr key={i}>
                                <th className="fixed-width">{i + 1}.</th>
                                <td className="w-50">{importer}</td>
                                <td>{toMetricTonString(ago)}</td>
                                <td>{toMetricTonString(pms)}</td>
                                <td>{toMetricTonString(jet)}</td>
                            </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className=""></td>
                            <td className="fw-bold">Total</td>
                            <td className="fw-bold">{total.ago.toLocaleString()}</td>
                            <td className="fw-bold">{total.pms.toLocaleString()}</td>
                            <td className="fw-bold">{total.jet.toLocaleString()}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        )
    }

    const getHeaderProps = () => {
        const props = [`${reportType.OvernightDifferences} Report`];

        if ((filter.from || filter.to) && filter.from === filter.to) {
            props.push(`Date: ${(filter?.from || filter?.to)?.toLocaleDateString()}`);
        } else if (filter.from && filter.to && filter.from !== filter.to) {
            props.push(`Period: ${filter.from.toLocaleDateString()} - ${filter.to.toLocaleDateString()}`);
        } else if (filter.from && !filter.to) {
            props.push(`From: ${filter.from.toLocaleDateString()}`);
        } else if (filter.to && !filter.from) {
            props.push(`Up to: ${filter.to.toLocaleDateString()}`);
        }
        return props;
    }

    const renderPrintVersion = () => {
        const element = renderComponent();
        return <PrintingVersion headerProps={getHeaderProps()} element={element} ref={componentRef} shouldRender={Boolean(reports.length)} />;
    }

    return (
        <>
            <div className="d-flex-jcb align-items-center p-2 border mt-3 mb-3">
                <div className="form-check form-check-inline px-0">
                    <DateRangePickerComponent placeholder="Select date" dateRange={{ from: filter.from, to: filter.to }} setDate={handleDateChange} />
                </div>
                <div className="d-flex-jcb align-items-center">
                    <Toggle disabled={loading} checked={isMetric} label="Metric tonnes" handlier={toggleUnit} />
                    <button
                        onClick={handlePrint}
                        className="btn btn-outline-dark fw-bold m-1 btn-sm">
                        <Icon icon={faPrint} color="dark" /> Print
                    </button>
                    {Button}
                    <button
                        onClick={() => setRefresh(true)}
                        className="btn btn-dark fw-bold m-1 btn-sm">
                        <Icon icon={faRefresh} color="white" /> Refresh
                    </button>
                </div>
            </div>
            {Boolean(errorMessage) && <Alert type={AlertType.DANGER} message={errorMessage} />}
            {loading ? <Loading /> : reports.length ? renderComponent() : <div>No results match your criteria.</div>}
            {renderPrintVersion()}
        </>
    )
}



