import { useEffect, useState } from "react";

function useLocalStorage<T>(key: string, defaultValue: T){
  const [value, setValue] = useState(() => {
    let currentValue;

    try {
      currentValue = JSON.parse(localStorage.getItem(key) || String(defaultValue));
        } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  const removeValue = (key:string) => localStorage.removeItem(key); 

  return [value, setValue, removeValue];
};

export const StorageKeys = {
  ADMIN_STORED_USERS: '__users__',
  AP_MENU : '__aapmenu__',
  AP_MENU_NONE : '__amenunone__',
  AP_USERS_MENU : '__apusersmenu__',
  AP_DISTRIBUTORS_MENU : '__apdistributorsmenu__',
  AP_IMPORTERS_MENU : '__apimportersmenu__',
  AP_PRODUCTS_MENU : '__approductsmenu__',
  AP_TANKS_MENU : '__apptanksmenu__',
  PAGINATION: '__pagination__',
  APP_FILTERS: '__appFilters__',
  APP_TOKEN: '__auth__',
  APP_CODES: '__appCodes__',
  STOCK_BALANCE_BY_PRODUCT: '__sbp__',
  DAILY_LOADING: '__daiload__',
  REPORT_TYPE: '__rety__',
  CONVERSION_TYPE: '__conver__',
  IMPORTATION: '__imp__',
  SALES_BY_IMPORTERS: '__sbyimp__',
  SALES_BY_TANKS: '__sbytank__',
  OND_BY_IMPOTERS: '__ONDbyimp__',
  LD_BY_IMPOTERS: '__ODbyimp__',
  PRODUCT_MOVEMENT_BY_IMPORTER: '__pmByImp__',
  ONLY_FULFILLED_AND_APPROVED: '__onlyFulfilledAndApproved__',
  INCLUDE_DELIVERED_TRANSFERS: '__includeDeliveredTransfers__',
}

export default useLocalStorage;